import React from 'react';
import { createUseStyles } from "react-jss";
import Button from "../../../components/Button";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import morgisLogo from '../../../assets/images/welcomePage/MorgisLogo.png';
import { getAvailableNamespaces } from '../../../translations';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getAppLink, redirectToExternalLink, remapURLSearchParams } from '../../../utilities/utils';
import { APP_ROUTE_LOGIN_LEADER, APP_ROUTE_SIGNUP_LEADER_SOCIAL } from '../../../utilities/constants/appRouteNames';
import { PARAM_USER_GROUP } from '../../../utilities/constants';
import { getUserGroup } from '../../../utilities/constants/user';

const useStyles = createUseStyles({
    getStartedWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    getStartedSection: {
        display: 'flex',
        padding: '32px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'stretch',
        borderRadius: '32px',
        background: 'linear-gradient(96deg, #E1D7FF -57.18%, #FFF 52.44%, #E1D7FF 159.18%)',
        boxShadow: '0px 0px 10.645px 0px rgba(0, 0, 0, 0.20)',
        maxWidth: '500px',
        width: '100%',
        "& > div": {
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
            alignItems: 'center',
            "& img": {},
            "& button": {
                display: 'flex',
                width: '330px',
                padding: '20px 109px',
            },
            "& h4": {
                margin: 0,
                color: '#000',
                textAlign: 'center',
                fontSize: '32px',
                fontWeight: '500',
                lineHeight: '38px',
                letterSpacing: '-0.639px'
            }
        }
    },
    getStartedSectionFooter: {
        display: 'flex',
        gap: '4px',
        "& .footerText": {
            color: '#959595',
            fontFamily: 'Inter',
            fontSize: '20px',
            fontWeight: '500',
            lineHeight: '24px', /* 119.76% */
            letterSpacing: '-0.479px',
        },
        "& .redirectLink": {
            color: '#000',
            fontSize: '20px',
            fontWeight: '500',
            lineHeight: '24px', /* 119.76% */
            letterSpacing: '-0.479px',
            textDecorationLine: 'underline',
            textDecorationStyle: 'solid',
            textDecorationSkipInk: 'none',
            textDecorationThickness: 'auto',
            textUnderlineOffset: 'auto',
            textUnderlinePosition: 'from-font',
            margin: 0,
            cursor: 'pointer'
        }
    },
    "@media (max-width: 768px)": {
        getStartedSection: {
            "& h4": {
                fontSize: '24px',
                lineHeight: '28.8px',
                letterSpacing: '-0.48px'
            },
            "& button": {
                fontSize: '16px',
                padding: '16px 80px',
            },
        },
        getStartedSectionFooter: {
            "& .footerText": {
                fontSize: '14px',
                lineHeight: '18px', /* 119.76% */
                letterSpacing: '-0.36px',
            },
            "& .redirectLink": {
                fontSize: '14px',
                lineHeight: '18px', /* 119.76% */
                letterSpacing: '-0.36px',
            }
        }
    },
});

const GetStartedSection = () => {
    const [t] = useTranslation(getAvailableNamespaces(), { useSuspense: false });
    const classes = useStyles();
    const location = useLocation()
    const redirectToSignUp = () => {
        redirectToExternalLink(
            getAppLink(APP_ROUTE_SIGNUP_LEADER_SOCIAL, [
                ...remapURLSearchParams(location.search),
                {
                    name: PARAM_USER_GROUP,
                    value: getUserGroup(),
                },
            ]))
    }
    const redirectToLogIn = () => {
        redirectToExternalLink(
            getAppLink(APP_ROUTE_LOGIN_LEADER, [
                ...remapURLSearchParams(location.search),
                {
                    name: PARAM_USER_GROUP,
                    value: getUserGroup(),
                },
            ]))
    }
    return (
        <div className={classes.getStartedWrapper}>
            <div className={classes.getStartedSection}>
                <img src={morgisLogo} alt="Morgis Logo" />
                <div>
                    <h4>{t('get_started_section.title')}</h4>
                    <Button onClick={redirectToSignUp} data-primary>{t('get_started_section.get_started_button')}</Button>
                    <div className={classes.getStartedSectionFooter}>
                        <span className="footerText">{t('get_started_section.footer_text')}</span>
                        <p onClick={redirectToLogIn} className="redirectLink">{t('get_started_section.login_link')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GetStartedSection;
