import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Link, useLocation } from "react-router-dom";
import ButtonGhost from "../../components/ButtonGhost";
import { useViewportSize } from "../../hooks/useViewportSize";
import { getAvailableNamespaces } from "../../translations";
import {
  PARAM_EMAIL,
  ROUTE_HOME,
  ROUTE_LANDING_GUEST,
  ROUTE_LANDING_ROOKIE,
} from "../../utilities/constants";
import {
  APP_ROUTE_SIGNUP_LEADER,
  APP_ROUTE_SIGNUP_LEADER_SOCIAL,
  APP_ROUTE_SIGNUP_ROOKIE_EMAIL,
} from "../../utilities/constants/appRouteNames";
import {
  getAppLink,
  redirectToExternalLink,
  remapURLSearchParams,
} from "../../utilities/utils";
import FacebookButton from "../../components/FacebookButton";
import GoogleButton from "../../components/GoogleButton";
import { EmailIconButton } from "../../assets/icons/icons";
import AuthWithEmailCTA from "./AuthWithEmailCTA";

const useStyles = createUseStyles((theme) => ({
  anonymousTourFooter: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    maxWidth: "720px",
    boxSizing: "border-box",
    paddingBottom: "20px",
    flexDirection: "column",
    [theme.mUp]: {
      position: "initial",
      maxWidth: "100%",
      alignItems: "center",
    },
  },
  buttonsWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
    maxWidth: "520px",
    marginBottom: "16px",

    "& > button": {
      borderRadius: "6px",
    },

  },
  ctaDescription: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "140%",
    color: theme.colors.darkPurple,
    [theme.mUp]: {
      display: "none",
    },
  },
  link: {
    alignSelf: "center",
    color: theme.colors.darkPurpleMidOpacity,
    textDecoration: "underline",
    cursor: "pointer"
  },
  buttonContainerRookie: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 5,
    [theme.lUp]: {
      gap: 10,
    },
  },

  buttonContainerLeader: {
    width: "100%",
    display: "flex",
    flexDirection: "column-reverse",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
    [theme.lUp]: {
      display: "flex",
      flexDirection: "row",
      gap: 10,
    },
  },
}));
const AnonymousTourCTA = () => {
  const { vh, vw } = useViewportSize();
  const [t] = useTranslation(getAvailableNamespaces(), { useSuspense: false });
  const location = useLocation();
  const screenSizeM = 768;
  const role = location.pathname === ROUTE_LANDING_ROOKIE ? "rookie" : "leader";
  const isDesktopSize = vw * 100 >= screenSizeM;
  const isLeader = role === "leader";
  const isRookie = role === "rookie";

  const search = location?.search;
  const params = new URLSearchParams(search);
  const isAuthWithEmailAvailable = !!parseInt(params.get(PARAM_EMAIL));

  const renderLabelButtonGhost = useMemo(() => {
    if (isLeader) {
      return isDesktopSize
        ? "anonymous_tour.continue_with_email"
        : "anonymous_tour.leader_button_email";
    } else {
      return isDesktopSize
        ? "anonymous_tour.continue_with_email"
        : "anonymous_tour.rookie_button";
    }
  }, [isLeader, isRookie, isDesktopSize]);

  const socialButton = (method) => {
    if (isLeader) {
      return t("signup.leader_method_choice", {
        METHOD: method,
      });
    } else {
      return isDesktopSize
        ? t("signup.leader_method_choice", {
          METHOD: method,
        })
        : t("signup.only_method", {
          METHOD: method,
        });
    }
  };

  const classes = useStyles({ vh });

  return (
    <div className={classes.anonymousTourFooter}>
      {isAuthWithEmailAvailable ? (
        <AuthWithEmailCTA role={role} />
      ) : (
        <div className={classes.buttonsWrapper}>
          {isRookie && (
            <p className={classes.ctaDescription}>
              {t("anonymous_tour.rookie_cta_description")}
            </p>
          )}

          {isLeader && (
            <>
              {/* <FacebookButton
                text={t("signup.leader_method_choice", {
                  METHOD: "Facebook",
                })}
              /> */}
              <GoogleButton
                text={t("signup.leader_method_choice", {
                  METHOD: "Google",
                })}
              />
            </>
          )}
          <ButtonGhost
            iconRight={<EmailIconButton />}
            callback={() =>
              redirectToExternalLink(
                isRookie
                  ? getAppLink(APP_ROUTE_SIGNUP_ROOKIE_EMAIL, [
                    ...remapURLSearchParams(location?.search),
                  ])
                  : getAppLink(APP_ROUTE_SIGNUP_LEADER, [
                    ...remapURLSearchParams(location?.search),
                  ])
              )
            }
            text={renderLabelButtonGhost}
          />
        </div>
      )}
    </div>
  );
};

export default AnonymousTourCTA;
