import { useEffect, useState } from "react";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { createUseStyles } from "react-jss";
import ChoosePaymentMethodSection from "../ChoosePaymentMethodSection/ChoosePaymentMethodSection";
import Popover from "../Popover";
import mastercardLogo from '../../assets/images/mastercard_logo.svg';
import visaLogo from '../../assets/images/visa_logo.svg';
import { RedirectIcon } from "../../assets/icons/icons";
import SessionExpireTimer from "./SessionExpireTimer";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../translations";
import { CreditCardsName, SocialPlatformType } from "../ChoosePaymentMethodSection/helpers";
import { useViewportSize } from "../../hooks/useViewportSize";
import { FormProvider, Controller, useForm } from "react-hook-form";
import AutoCompleteAddressController from "../AutoCompleteAddress/AutoCompleteAddressController";
import PhoneNumberInput from "../PhoneNumberInput";
import { AddressSchema, CHECKOUT_FIELDS } from "../../pages/rookieProfilePage/MorgisCheckoutModal/helpers";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Spinner from "../Spinner";
import customAxios from "../../utilities/axios";
import Alert from "../Alert";
import { useFormsContext } from "../../pages/rookieProfilePage/MorgisCheckoutModal/context";


const useStyles = createUseStyles((theme) => ({
    subTitle: {
        fontSize: '16px',
        fontWeight: '700',
        textAlign: 'center',
        color: '#000000',
        marginBottom: '16px'
    },
    modalBody: {
        padding: '0 24px 24px',
        maxWidth: '394px',
        height: '100%',
        display: 'grid',
        gridTemplateRows: '1fr auto',
        justifyContent: 'center',
    },
    modalRoot: {
        width: '394px',
        height: '600px',
    },
    infoBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        marginTop: '16px'
    },
    iconContainer: {
        display: 'flex',
        gap: '16px',
        alignItems: "center",
        textTransform: 'uppercase',
        fontWeight: '500',
        lineHeight: '24px',
        "& img": {
            width: '22px',
            height: '16px'
        }
    },
    processedInfo: {
        fontSize: '10px',
        fontWeight: '300',
        color: '#A8A8A8',
        opacity: '0.8',
        "& svg": {
            width: '12px',
            height: '10px'
        }
    },
    timer: {
        textAlign: 'center',
        margin: '8px 0 16px 0',
        "& span": {
            fontSize: '12px',
            fontWeight: '600',
            color: '#A8A8A8',
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        height: '100%'
    },
    alert: {
        position: 'fixed',
        top: 92,
        zIndex: 1000,
        left: '50%',
        transform: 'translateX(-50%)',
        minWidth: '96%'
    },
    '@media (max-width: 768px)': {
        modalRoot: {
            width: '100%',
            height: '100%',
            borderRadius: '0 !important',
        },
        root: {
            height: '100%',
        },
        modalBody: {
            margin: '0 auto',
        },
        form: {
            gap: '6px',
        },
        alert: {
            top: 160,
            minWidth: 900
        }
    },
    styledAddOtherMethod: ({ height, isUpdateCreditCard, isMonthlySupport }) => ({
        borderRadius: '18px',
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.1)',
        marginTop: '13px',
        height: '100%',
        minHeight: isUpdateCreditCard
            ? '430px'
            : isMonthlySupport
                ? `calc(${height}px - 250px)`
                : `calc(${height}px - 425px)`,

        // Media queries
        [theme.mediaQueries.m]: {
            minHeight: isUpdateCreditCard ? '430px' : isMonthlySupport ? '430px' : '260px',
        },

        '& iframe': {
            borderRadius: '18px',
            border: 'none',
        },
    }),
}));

const MorgisCheckoutValidationStep2Schema = yup.object().shape({
    [CHECKOUT_FIELDS.phone]: yup.string(),
    [CHECKOUT_FIELDS.street]: AddressSchema.optional()
});

export const PaymentMethodVariantIframe = ({ paymentType, onClose, dataToShow, selectedPackage, firstPartUserData, rookieName, defaultVariant }) => {
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const { height } = useViewportSize()
    const classes = useStyles({ height });
    const [paymentsMethods, setPaymentsMethods] = useState(null)
    const [variant, setVariant] = useState(defaultVariant)
    const [isLoading, setIsLoading] = useState(false)
    const [toolTipError, setToolTipError] = useState(null)
    const SESSION_DURATION = 5 // minutes
    const onTimeExpired = () => {
        onClose()
    }
    const location = useLocation()
    const selectedObjectKey = Object.keys(dataToShow).find(key => dataToShow[key] === defaultVariant)
    useEffect(() => {
        if (paymentsMethods) {
            if(paymentsMethods.urls[CreditCardsName.PRAXIS_CREDIT_CARD]) {
                setVariant(paymentsMethods.urls[CreditCardsName.PRAXIS_CREDIT_CARD])
            } else {
                setVariant(paymentsMethods.urls[Object.keys(paymentsMethods.urls)[0]])
            }
        }
    }, [paymentsMethods])
    const { formsData, updateForm, updateErrors, clearErrors } = useFormsContext();

    const methods = useForm({
        mode: 'all',
        reValidateMode: 'all',
        nativeValidation: false,
        defaultValues: {
            [CHECKOUT_FIELDS.phone]: formsData[CHECKOUT_FIELDS.phone],
            [CHECKOUT_FIELDS.street]: formsData[CHECKOUT_FIELDS.street],
            [CHECKOUT_FIELDS.paymentMethod]: formsData[CHECKOUT_FIELDS.paymentMethod],
            [CHECKOUT_FIELDS.RowAddress]: formsData[CHECKOUT_FIELDS.RowAddress],
        },
        resolver: yupResolver(MorgisCheckoutValidationStep2Schema),
    });
    const { formState: { isValid }, control, getValues, setError, clearErrors: clearFormErrors } = methods;
    const formValues = getValues()

    const onSubmit = async (data) => {
        const dataToSend = {
            ...firstPartUserData,
            phone_number: data[CHECKOUT_FIELDS.phone],
            city: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.city],
            country_name: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.countryName],
            country_code: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.countryCode],
            state_code: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.stateCode],
            zip_code: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.zipCode],
            address: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.address],
            raw_address: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.rowAddress],
        }
        updateForm({
            [CHECKOUT_FIELDS.phone]: data[CHECKOUT_FIELDS.phone],
            [CHECKOUT_FIELDS.street]: data[CHECKOUT_FIELDS.street],
            [CHECKOUT_FIELDS.paymentMethod]: data[CHECKOUT_FIELDS.paymentMethod],
            [CHECKOUT_FIELDS.street]: {
                [CHECKOUT_FIELDS.address]: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.address],
                [CHECKOUT_FIELDS.rowAddress]: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.rowAddress],
                [CHECKOUT_FIELDS.city]: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.city],
                [CHECKOUT_FIELDS.countryCode]: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.countryCode],
                [CHECKOUT_FIELDS.countryName]: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.countryName],
                [CHECKOUT_FIELDS.stateCode]: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.stateCode],
                [CHECKOUT_FIELDS.zipCode]: data[CHECKOUT_FIELDS.street][CHECKOUT_FIELDS.zipCode]
            },
        })
        try {
            setIsLoading(true)
            const res = await customAxios.post(`/auth/signup/anonymous`, dataToSend)
            setPaymentsMethods(res.data)
            if (typeof window !== 'undefined') {
                localStorage.setItem("paymentStartLocation", location.pathname)
                localStorage.setItem("userIdForPaymentStatus", res?.data?.user?.id)
                localStorage.setItem("userEmailForPaymentStatus", dataToSend[CHECKOUT_FIELDS.email])
                localStorage.setItem("hostNameForPaymentsStatus", rookieName)
                clearErrors()
                clearFormErrors()

            }
        } catch (err) {
            if (err) {
                const errorKey = Object.keys(err.response.data)[0]
                const isStreetError = [CHECKOUT_FIELDS.address, CHECKOUT_FIELDS.rowAddress, CHECKOUT_FIELDS.city, CHECKOUT_FIELDS.countryCode, CHECKOUT_FIELDS.countryName, CHECKOUT_FIELDS.stateCode, CHECKOUT_FIELDS.zipCode].includes(errorKey)
                const isMobilePhone = [CHECKOUT_FIELDS.phone].includes(errorKey)
                const message = err.response.data[errorKey][0]
                if (!isStreetError) {
                    updateErrors(errorKey, message)
                    if (!isStreetError && !isMobilePhone)
                        onClose()
                }
                else {
                    updateErrors(CHECKOUT_FIELDS.street, message)
                    setError(CHECKOUT_FIELDS.street, message)
                }
                setToolTipError(message)
            }
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        if (isValid && !!formValues.phone && typeof formValues.street === 'object' && !toolTipError) {
            onSubmit(formValues)
        }
    }, [isValid, formValues.street, formValues.phone])


    return (
        <Popover onClose={onClose}
            withHeader
            title={t('morgis_iframe_modal.welcome')}
            className={classes.modalRoot}
            bodyClassName={classes.modalBody}>
            <div>
                {isLoading && <Spinner />}
                {toolTipError &&
                    <Alert
                        visible={toolTipError}
                        message={toolTipError}
                        dismissTimeout={3000}
                        dismissCallback={() => {
                            setToolTipError(null)

                        }}
                        className={classes.alert}
                    />}
                <div>
                    {!!selectedPackage && <div className={classes.subTitle}>{t('morgis_iframe_modal.buy_morgis', { Morgis: selectedPackage.micromorgi_count, CURRENCY: '$', AMOUNT: selectedPackage.price })}</div>}
                </div>
                <div>
                    <ChoosePaymentMethodSection paymentType={paymentType} defaultVariant={variant} onChange={setVariant} label={t('morgis_iframe_modal.set_payment_method')} paymentsList={paymentsMethods?.urls || dataToShow} />
                </div>
                <div className={classes.infoBox}>
                    {!!paymentsMethods?.urls && <>
                        <div className={classes.iconContainer}>
                            <img src={visaLogo} /> <img src={mastercardLogo} /> {t('morgis_iframe_modal.pay_with_credit_or_debit_card')}
                        </div>
                        <a className={classes.processedInfo} href={paymentsMethods?.urls[CreditCardsName.CCBIL_CREDIT_CARD]}>
                            {t('morgis_iframe_modal.processed_by_trustpay')} {t('morgis_iframe_modal.ccbill')} <RedirectIcon />
                        </a>
                    </>
                    }
                    <FormProvider {...methods}>
                        <form className={classes.form}>
                            <Controller
                                name={CHECKOUT_FIELDS.phone}
                                control={control}
                                render={({ onChange }) =>
                                    <PhoneNumberInput
                                        onChange={(phone, meta) => {
                                            updateForm({
                                                [CHECKOUT_FIELDS.phoneCode]: meta.code,
                                                [CHECKOUT_FIELDS.phoneNumber]: meta.phone
                                            })
                                            onChange(phone)
                                        }}
                                        defaultValues={{ code: formsData[CHECKOUT_FIELDS.phoneCode], phone: formsData[CHECKOUT_FIELDS.phoneNumber] }}
                                        labels={[t('morgis_checkout_modal.phone.phone_code_label'), t('morgis_checkout_modal.phone.phone_label')]}
                                    />
                                }
                            />
                            <AutoCompleteAddressController
                                name={CHECKOUT_FIELDS.street}
                                innerFields={
                                    [
                                        [CHECKOUT_FIELDS.rowAddress],
                                        [CHECKOUT_FIELDS.address],
                                        [CHECKOUT_FIELDS.city],
                                        [CHECKOUT_FIELDS.countryCode],
                                        [CHECKOUT_FIELDS.countryName],
                                        [CHECKOUT_FIELDS.stateCode],
                                        [CHECKOUT_FIELDS.zipCode]
                                    ]
                                } />
                        </form>
                    </FormProvider>
                    {!!paymentsMethods?.urls && <div className={classes.timer}>
                        <SessionExpireTimer minutes={SESSION_DURATION} onComplete={onTimeExpired} isReInitTimer />
                    </div>}
                </div>
                {!!paymentsMethods?.urls &&
                    <>
                        <div>
                            {t('morgis_iframe_modal.billing_to', { USERNAME: firstPartUserData[CHECKOUT_FIELDS.customerName] })}
                        </div>
                        <div className={classes.styledAddOtherMethod}>
                            <iframe src={variant} height={'100%'} width={'100%'} />
                        </div>
                    </>}
            </div>
        </Popover>
    )
}