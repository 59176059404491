import { createContext, useContext, useState } from "react";
import { CHECKOUT_FIELDS } from "./helpers";

const FormsContext = createContext(undefined);

export const FormsProvider = ({ children }) => {
    const [formsData, setFormsData] = useState({
        //1 step
        [CHECKOUT_FIELDS.customerName]: '',
        [CHECKOUT_FIELDS.email]: '',
        //2 step
        [CHECKOUT_FIELDS.phone]: '',
        [CHECKOUT_FIELDS.phoneCode]: '',
        [CHECKOUT_FIELDS.phoneNumber]: '',
        [CHECKOUT_FIELDS.street]: '',
        [CHECKOUT_FIELDS.paymentMethod]: '',
        [CHECKOUT_FIELDS.address]: {
            [CHECKOUT_FIELDS.city]: '',
            [CHECKOUT_FIELDS.countryCode]: '',
            [CHECKOUT_FIELDS.countryName]: '',
            [CHECKOUT_FIELDS.stateCode]: '',
            [CHECKOUT_FIELDS.zipCode]: ''
        },

    });
    const [errors, setErrors] = useState({})

    const updateForm = (form) => {
        setFormsData((prev) => ({
            ...prev, ...form,
        }));
    };
    const updateErrors = (key, message) => {
        setErrors((prev) => ({
            ...prev, [key]: { message },
        }));
    };
    const clearErrors = (form) => {
        setErrors({});
    };
    return (
        <FormsContext.Provider value={{ formsData, updateForm, errors, updateErrors, clearErrors }}>
            {children}
        </FormsContext.Provider>
    );
};

export const useFormsContext = () => {
    const context = useContext(FormsContext);
    if (!context) {
        throw new Error("useFormsContext must be used within a FormsProvider");
    }
    return context;
};