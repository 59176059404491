import React from 'react';
import { InputHelpText } from '@/components/ui/input-help-text/InputHelpText';
import { Label } from '@/components/ui/label/Label';
import AutoCompleteAddress from './AutoCompleteAddress';
import { Controller, useFormContext } from 'react-hook-form';

const AutoCompleteAddressController = ({ name, label, helpText, rules, innerFields }) => {
  const {
    control,
    formState: { errors, defaultValues },
    trigger,
    clearErrors,
    getValues
  } = useFormContext();

  const value = getValues()[name]
  const error = errors[name];


  const keyOfInnerError = innerFields?.find(fieldName => errors[fieldName]);
  const innerError = errors[keyOfInnerError];
  const defaultValue = defaultValues?.[name] || value;


  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={(field) => (
          <AutoCompleteAddress
            name={name}
            defaultValue={defaultValue}
            error={innerError?.message || error && Object.keys(error)
              ?.filter(key => !isNaN(key))
              .map(key => error[key])
              .join("")}
            onChange={address => {
              if (address?.address === field.value?.address) return;
              field.onChange(address);
              clearErrors(innerFields);
              trigger(name);
            }}
            onBlur={() => {
              trigger(name);
            }}
          />
        )}
      />
    </>
  );
};

export default AutoCompleteAddressController;