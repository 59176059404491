import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { createUseStyles } from "react-jss";
import * as yup from 'yup';
import Button from "../../../components/Button";
import InputText from "../../../components/InputText";
import Popover from "../../../components/Popover";
import { useTranslation } from "react-i18next";
import { getAvailableNamespaces } from "../../../translations";
import axios from '../../../utilities/axios';
import { PaymentMethodVariantIframe } from "../../../components/PaymentMethodVariantIframe/PaymentMethodVariantIframe";
import { useEffect, useState } from "react";
import Spinner from "../../../components/Spinner";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { CHECKOUT_FIELDS } from "./helpers";
import ChoosePaymentMethodSection from "../../../components/ChoosePaymentMethodSection/ChoosePaymentMethodSection";
import { CreditCardsName, SocialPlatformType } from "../../../components/ChoosePaymentMethodSection/helpers";
import { FormsProvider, useFormsContext } from "./context";

const useStyles = createUseStyles({
    root: {

    },
    bodyRoot: {
        display: 'flex',
        width: '375px',
        height: '667px',
        padding: '24px 8px',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        flexShrink: 0,
        paddingTop: 0
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        height: '100%'
    },
    submit: {
        marginTop: 'auto'
    },
    '@media (max-width: 768px)': {
        root: {
            height: '100%',
            width: '100%',
            top: '0',
            left: 'unset',
            transform: 'none',
            borderRadius: 0
        },
        bodyRoot: {
            margin: '0 auto',
            height: '100%'
        },
        form: {
            gap: '6px',
        }
    }
});

const MorgisCheckoutValidationSchema = yup.object().shape({
    [CHECKOUT_FIELDS.customerName]: yup
        .string()
        .required('validation:required_first_name')
        .test(
            'has-two-words',
            'validation:required_last_name',
            (value) => value && value.trim().split(/\s+/).length >= 2
        ),
    [CHECKOUT_FIELDS.email]: yup
        .string()
        .email('validation:invalid_email')
        .required('validation:required_email'),
});

export const paymentTypes = {
    subscription: 'subscription',
    micromorgi: 'micromorgi'
}

const MorgisCheckoutModal = ({ onClose, morgisPackage, subscriptionPlan, paymentType, rookieId, selectedPackage, rookieName }) => {
    const [t] = useTranslation(getAvailableNamespaces(), {
        react: { useSuspense: false },
    });
    const { formsData, updateForm, errors: contextErrors, clearErrors } = useFormsContext();
    const [isOpenPaymentMethodVariantIframe, setIsOpenPaymentMethodVariantIframe] = useState(false)
    const classes = useStyles();
    const [firstPartUserData, setFirstPartUserData] = useState(null)
    const [paymentsMethods, setPaymentsMethods] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [variant, setVariant] = useState()
    const location = useLocation()


    const methods = useForm({
        mode: 'all',
        reValidateMode: 'all',
        nativeValidation: false,
        defaultValues: {
            [CHECKOUT_FIELDS.customerName]: formsData[CHECKOUT_FIELDS.customerName],
            [CHECKOUT_FIELDS.email]: formsData[CHECKOUT_FIELDS.email],
        },
        resolver: yupResolver(MorgisCheckoutValidationSchema),
    });

    const { handleSubmit, register, formState: { touched, errors }, control, setValue } = methods;

    const getGateways = async () => {
        try {
            setIsLoading(true)
            const res = await axios.get(`/profile/gateways${morgisPackage ? '?amount=' + morgisPackage.micromorgi_count : ''}`)
            const correctData = res.data.reduce((acc, item) => {
                acc[item.system_name] = item.gateway_hash;
                return acc;
            }, {});
            setPaymentsMethods(correctData)
        } catch (err) {
            if (err) {
                const errorKey = Object.keys(err.response.data)[0]
                setError(err.response.data[errorKey][0])
            }
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getGateways()
    }, [])

    useEffect(() => {
        if (paymentsMethods) { 
            if(paymentsMethods[CreditCardsName.PRAXIS_CREDIT_CARD]) {
                setVariant(paymentsMethods[CreditCardsName.PRAXIS_CREDIT_CARD])
            } else {
                setVariant(paymentsMethods[Object.keys(paymentsMethods)[0]])
            }
        }
    }, [paymentsMethods])

    const onSubmit = async (data) => {
        const dataToSend = {
            type: "leader",
            email: data[CHECKOUT_FIELDS.email],
            customer_name: data[CHECKOUT_FIELDS.customerName],
            payment_type: paymentType,
            micromorgi_package_id: morgisPackage?.id,
            subscription_package_id: subscriptionPlan?.id,
            rookie_id: rookieId,
        }
        updateForm({
            [CHECKOUT_FIELDS.email]: data[CHECKOUT_FIELDS.email],
            [CHECKOUT_FIELDS.customerName]: data[CHECKOUT_FIELDS.customerName],
            [CHECKOUT_FIELDS.paymentMethod]: paymentType
        })
        clearErrors()
        setFirstPartUserData(dataToSend)
        setIsOpenPaymentMethodVariantIframe(true)
    };
    useEffect(() => {
        if (!isOpenPaymentMethodVariantIframe) {
            setValue(CHECKOUT_FIELDS.email, formsData[CHECKOUT_FIELDS.email])
            setValue(CHECKOUT_FIELDS.customerName, formsData[CHECKOUT_FIELDS.customerName])
        }
    }, [isOpenPaymentMethodVariantIframe])
    return (
        <>
            {!isOpenPaymentMethodVariantIframe && <Popover
                onClose={onClose}
                withHeader
                title={t('morgis_checkout_modal.title')}
                className={classes.root}
                bodyClassName={classes.bodyRoot}
            >
                {isLoading && <Spinner />}
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                        <InputText
                            name={CHECKOUT_FIELDS.customerName}
                            errors={errors[CHECKOUT_FIELDS.customerName] || contextErrors[CHECKOUT_FIELDS.customerName]}
                            touched={touched[CHECKOUT_FIELDS.customerName]}
                            ref={register}
                            label={`${t('morgis_checkout_modal.customer_name.label')}*`}
                            placeholder={t('morgis_checkout_modal.customer_name.placeholder')}
                        />
                        <InputText
                            name={CHECKOUT_FIELDS.email}
                            errors={errors[CHECKOUT_FIELDS.email] || contextErrors[CHECKOUT_FIELDS.email]}
                            touched={touched[CHECKOUT_FIELDS.email]}
                            ref={register}
                            label={`${t('morgis_checkout_modal.email.label')}*`}
                            placeholder={t('morgis_checkout_modal.email.placeholder')}
                        />
                        {!!paymentsMethods && <div>
                            <ChoosePaymentMethodSection paymentType={paymentType} defaultVariant={variant} onChange={setVariant} label={t('morgis_iframe_modal.set_payment_method')} paymentsList={paymentsMethods} />
                        </div>}
                        <Button className={classes.submit} type="submit">
                            {t('morgis_checkout_modal.submit')}
                        </Button>
                    </form>
                </FormProvider>
            </Popover>
            }

            {isOpenPaymentMethodVariantIframe &&
                <PaymentMethodVariantIframe
                    paymentType={paymentType}
                    onClose={() => { setIsOpenPaymentMethodVariantIframe(false) }}
                    dataToShow={paymentsMethods}
                    selectedPackage={selectedPackage}
                    firstPartUserData={firstPartUserData}
                    rookieName={rookieName}
                    defaultVariant={variant}
                />
            }
        </>
    );
};

export default (props) =>
    <FormsProvider>
        <MorgisCheckoutModal {...props} />
    </FormsProvider>;