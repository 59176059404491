import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from 'react-i18next';
import { InstagramIcon, TwitterXIcon } from '../assets/icons/icons';
import morgisLogo from '../assets/images/footer/morgisLogo.png';
import { getAvailableNamespaces } from '../translations';
import { ROUTE_COMMUNITY_GUILDINES, ROUTE_FAQ_LEADER, ROUTE_FAQ_ROOKIE, ROUTE_LANDING_ROOKIE, ROUTE_PRIVACY_POLICY, ROUTE_TOS_LEADER, ROUTE_WHAT_IS_MORGI } from '../utilities/constants';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = createUseStyles({
    footer: {
        display: 'flex',
        padding: '48px 24px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '24px',
        background: 'linear-gradient(180deg, #FFF 0%, #BEBFFE 100%)',
        "& > div": {
            maxWidth: '674px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',

            "& > a": {
                color: '#000',
                textAlign: 'center',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '26px', /* 162.5% */
                textTransform: 'capitalize',
            },
            "& svg": {
                cursor: 'pointer'
            }
        },
        '& a': {
            color: '#fff',
            textDecoration: 'none',
            margin: '0 10px',
        },
        '& a:hover': {
            textDecoration: 'underline',
        },
        "& > p": {
            color: '#000',
            textAlign: 'center',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '26px', /* 162.5% */
            textTransform: 'capitalize',
            display: 'flex',
            gap: '2px',
            cursor: 'default',
            userSelect: 'none',
            "& > span": {
                color: '#8649D1',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '26px',
                textTransform: 'capitalize',
            }
        }
    },
    socialMedias: {
        justifyContent: 'center !important',
        display: 'flex',
        gap: '56px'
    }
});

const Footer = () => {
    const [t] = useTranslation(getAvailableNamespaces(), { useSuspense: false });
    const classes = useStyles();
    const location = useLocation()
    const role = location.pathname === ROUTE_LANDING_ROOKIE ? "rookie" : "leader";
    const isRookie = role === "rookie"
    return (
        <footer className={classes.footer}>
            <img src={morgisLogo} alt={t('footer.logoAlt')} />
            <div>
                <Link to={ROUTE_TOS_LEADER}>{t('footer.termsOfService')}</Link>
                <Link to={ROUTE_PRIVACY_POLICY}>{t('footer.privacyPolicy')}</Link>
            </div>
            <div>
                <Link to={isRookie ? ROUTE_FAQ_ROOKIE : ROUTE_FAQ_LEADER}>{t('footer.faq')}</Link>
                <a href="https://support.morgis.com" target="_blank" rel="noopener noreferrer">{t('footer.help')}</a>
                <Link to={ROUTE_COMMUNITY_GUILDINES}>{t('footer.guidelines')}</Link>
                <Link to={ROUTE_WHAT_IS_MORGI}>{t('footer.blog')}</Link>
            </div>
            <div className={classes.socialMedias}>
                <a href='https://www.tiktok.com/@morgisocial' target="_blank" rel="noopener noreferrer">
                    <TwitterXIcon />
                </a>
                <a href='https://www.instagram.com/morgis.br/' target="_blank" rel="noopener noreferrer">
                    <InstagramIcon />
                </a>
            </div>
            <p>
                {t('footer.copyright')}
                <span>{t('footer.allRightsReserved')}</span>
            </p>
        </footer>
    );
};

export default Footer;
