import { forwardRef, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import InputText from "./InputText";

const useStyles = createUseStyles({
    root: {
        display: 'flex',
        gap: '8px',
        maxHeight: '94px',
    },
    label: {
        marginBottom: '2px',
    },
    phoneCode: {
        width: '120px',
        margin: '6px 0 9px',
        height: '100%',
        "& input": {
            height: '100% !important',
            width: '100% !important',
        },
        '& form-control': {
            border: '1px solid rgb(222, 229, 239)',
        },
        "& div.flag-dropdown ": {
            width: '100% !important',
            border: '1px solid rgb(222, 229, 239)',
            '&.open': {
                backgroundColor: 'transparent !important'
            }
        },
        "& div.selected-flag": {
            width: '100% !important',
            "&:focus": {
                background: 'transparent '
            },
            "&:hover": {
                background: 'transparent'
            }
        },
    },
    phoneCodeWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: '1',
        "& .arrow": {
            display: 'none'
        },
    },
    phoneInput: {
        flex: '1',
    },
    inputClass: {
        '&::-webkit-outer-spin-button': {
            appearance: 'none',
            margin: 0,
        },
        '&::-webkit-inner-spin-button': {
            appearance: 'none',
            margin: 0,
        },

        '&[type="number"]': {
            appearance: 'textfield',
        },
    }
});

// Wrap component with forwardRef
const PhoneNumberInput = forwardRef(({ onChange, labels = ['Country', 'Phone number'], defaultValues }, ref) => {
    const classes = useStyles();
    const [phoneCode, setPhoneCode] = useState(defaultValues.code || '');
    const [phoneValue, setPhoneValue] = useState(defaultValues.phone || '');

    useEffect(() => {
        onChange(`+${phoneCode}${phoneValue}`, { code: phoneCode, phone: phoneValue });
    }, [phoneCode, phoneValue]);

    return (
        <div className={classes.root}>
            <div className={classes.phoneCodeWrapper}>
                <label className={classes.label}>{labels[0]}</label>

                <PhoneInput
                    value={phoneCode}
                    onChange={setPhoneCode}
                    className={classes.phoneCode}
                    buttonStyle={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        background: 'transparent',
                        width: '100% !important'
                    }}
                    inputStyle={{
                        cursor: 'pointer'
                    }}
                    inputProps={{
                        readOnly: true,
                        placeholder: ''
                    }}
                />
            </div>

            <InputText
                ref={ref}  // Pass the ref to InputText if needed
                disabled={!phoneCode}
                type='number'
                label={labels[1]}
                className={classes.phoneInput}
                inputClassName={classes.inputClass}
                onChange={(e) => { setPhoneValue(e.target.value) }}
                defaultValue={phoneValue}
            />
        </div>
    );
});

export default PhoneNumberInput;
