import { createUseStyles } from "react-jss";
import FeaturesSection from "./components/FeaturesSection";
import HeroSection from "./components/HeroSection";
import TestimonialsSection from "./components/TestimonialsSection";
import GetStartedSection from "./components/GetStartedSection";

const useStyles = createUseStyles({
    welcomePageWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        overflow: 'hidden'
    },
    welcomePageContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '152px',
        marginBottom: '152px'
    },
    '@media (max-width: 768px)': {
        welcomePageContentWrapper: {
            gap: '24px',
            marginBottom: '44px'
        }
    }
})

const WelcomePage = () => {
    const classes = useStyles()
    return (
        <div className={classes.welcomePageWrapper}>
            <HeroSection />
            <div className={classes.welcomePageContentWrapper}>
                <FeaturesSection />
                <TestimonialsSection />
                <GetStartedSection />
            </div>

        </div>
    );
};
export default WelcomePage