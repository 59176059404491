import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
    },
    "@media (max-width: 1024px)": {
        container: {
            minHeight: '438px',
        }
    },
});



const AnimatedCardDeck = () => {
    const [hasAnimated, setHasAnimated] = useState(false);
    const containerRef = useRef(null);
    const classes = useStyles();
    const { i18n: { language } } = useTranslation()
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !hasAnimated) {
                    setHasAnimated(true);
                }
            },
            { threshold: 1.0 }
        );

        if (containerRef.current) observer.observe(containerRef.current);
        return () => observer.disconnect();
    }, [hasAnimated]);

    const getImagesForLanguage = () => Array.from({ length: 5 }, (_, i) =>
        require(`../../../assets/images/welcomePage/${language}/girl${i + 1}.svg`)
    );


    return (
        <div ref={containerRef} className={classes.container}>
            {hasAnimated && (
                getImagesForLanguage().map((card, index) => (
                    <Card key={index} index={index} url={card} />
                ))
            )}
        </div>
    );
};

const useStylesCard = createUseStyles({
    card: {
        position: 'absolute',
        width: 260,
        height: 350,
        background: 'white',
        borderRadius: 10,
        boxShadow: '0 5px 20px rgba(0, 0, 0, 0.3)',
        transform: 'translateY(100px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 0,
        animation: '$flyIn 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards',
        animationIterationCount: 1,
    },
    '@keyframes flyIn': {
        '0%': {
            transform: 'translateY(100px) scale(1.5) rotate(0deg)',
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },
});

const Card = ({ index, url }) => {
    const classes = useStylesCard({ index });
    return (
        <div
            className={classes.card}
            style={{
                backgroundImage: `url(${url})`,
                animationDelay: `${index * 0.8}s`,
                zIndex: index,
                // transform: `translateX(0px) scale(1) rotate(${Math.floor(Math.random() * 21) - 5}deg)`,
                transform: `translateX(0px) scale(1) rotate(${(index - 2) * 5}deg)`,
            }}
        />
    );
};

export default AnimatedCardDeck;
