import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import AnimatedCardDeck from './AnimatedCardDeck';
import { getAvailableNamespaces } from '../../../translations';
import { getAppLink, redirectToExternalLink, remapURLSearchParams } from '../../../utilities/utils';
import { PARAM_PERSONA, PARAM_USER_GROUP, ROUTE_LANDING_ROOKIE } from '../../../utilities/constants';
import { getUserGroup } from '../../../utilities/constants/user';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { APP_ROUTE_SIGNUP_LEADER_SOCIAL, APP_ROUTE_SIGNUP_ROOKIE_EMAIL } from '../../../utilities/constants/appRouteNames';
import MorgisPackagesModal from '../../rookieProfilePage/MorgisPackagesModal/MorgisPackagesModal';
import Button from '../../../components/Button';

const useStyles = createUseStyles({
    features: {
        display: 'flex',
        flexDirection: 'column',
        gap: '152px',
        backgroundColor: '#fff',
        textAlign: 'center',
    },
    featureCard: {
        display: 'grid',
        gridTemplateColumns: "repeat(2, 1fr)",
        marginBottom: '30px',
        padding: '20px',
        borderRadius: '8px',
        gridTemplateAreas: `"title iw" "content iw"`,
        width: '100%',
        gap: '32px',
        '& .content': {
            gridArea: 'content',
            maxWidth: '500px',
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '48px'
        },
        '& h3': {
            transition: 'all 0.5s',
            gridArea: 'title',
            color: "#000",
            textAlign: 'center',
            fontFamily: 'Inter',
            fontSize: '47px',
            fontWeight: '700',
            letterSpacing: '-2.35px',
            margin: 0,
            textWrap: 'balance',
        },
        '& p': {
            color: "rgba(0, 0, 0, 0.80)",
            textAlign: 'center',
            fontSize: '20px',
            fontWeight: '500',
            lineHeight: '130%',
            margin: 0,
            textWrap: 'balance',
        },
        '& button': {
            cursor: 'pointer',
            display: 'flex',
            width: '319px',
            padding: '16px 8px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '82.579px',
            background: '#FDBA0D !important',
            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)',
            border: 'none',
            color: '#FFF',
            textAlign: 'center',
            fontSize: '20px',
            fontWeight: '600',
            textTransform: 'capitalize',
        }
    },
    imageWrapper: {
        gridArea: 'iw',
        width: '150px',
        borderRadius: '10px',
        height: "100%",
        width: "100%"
    },
    reverse: {
        gridTemplateAreas: `"iw title" "iw content"`,
        '& button': {
            background: '#8649D1 !important'
        }
    },
    goalImage: {
        boxShadow: "0px 0px 10.314px 0px #FC9F21",
        borderRadius: 22
    },
    '@media (max-width: 1024px)': {
        featureCard: {
            gridTemplateColumns: "1fr",
            gridTemplateAreas: `"title" "iw" "content"`,
            placeItems: 'center',
            '& h3': {
                fontSize: '24px',
                letterSpacing: '-1.2px'
            },
            '& p': {
                fontSize: '16px',
                letterSpacing: '-0.8px'
            },
            '& button': {
                fontSize: '16px',
            }
        },
        features: {
            gap: '24px'
        },
        imageWrapper: {
            "& img": {
                maxWidth: '343px',
            }
        }
    },
});

const getImagePath = (language, imageName) =>
    require(`../../../assets/images/welcomePage/${language}/FeaturesSection${imageName}.png`);


const FeaturesSection = () => {
    const [t] = useTranslation(getAvailableNamespaces(), { useSuspense: false });
    const { i18n: { language } } = useTranslation()
    const classes = useStyles();
    const location = useLocation()

    const imagesPack = {
        buyMorgiCard: getImagePath(language, "BuyMorgisCard"),
        goal: getImagePath(language, "GoalCard"),
    };
    const redirectToSignUp = () => {
        redirectToExternalLink(
            getAppLink(APP_ROUTE_SIGNUP_LEADER_SOCIAL, [
                ...remapURLSearchParams(location.search),
                {
                    name: PARAM_USER_GROUP,
                    value: getUserGroup(),
                },
            ]))
    }
    const [showMorgiPackagesModal, setShowMorgiPackagesModal] = useState(false)
    return (<>
        <section className={classes.features}>
            <div className={classes.featureCard}>
                <div className={classes.imageWrapper}>
                    <AnimatedCardDeck />
                </div>
                <h3>{t('featuresSection.title1')}</h3>
                <div className="content">
                    <p>{t('featuresSection.description1')}</p>
                    <Button data-primary onClick={redirectToSignUp}>
                        {t('featuresSection.button1')}</Button>
                </div>
            </div>
            <div className={cx(classes.featureCard, classes.reverse)}>
                <div className={classes.imageWrapper}>
                    <img src={imagesPack.buyMorgiCard} alt={t('featuresSection.title2')} />
                </div>
                <h3>{t('featuresSection.title2')}</h3>
                <div className="content">
                    <p>{t('featuresSection.description2')}</p>
                    <Button data-primary onClick={() => {
                        setShowMorgiPackagesModal(true)
                    }}>
                        {t('featuresSection.button2')}
                    </Button>
                </div>
            </div>
            <div className={classes.featureCard}>
                <div className={classes.imageWrapper}>
                    <img src={imagesPack.goal} className={classes.goalImage} alt={t('featuresSection.title3')} />
                </div>
                <h3>{t('featuresSection.title3')}</h3>
                <div className="content">
                    <p>{t('featuresSection.description3')}</p>
                    <Button data-primary onClick={redirectToSignUp}>
                        {t('featuresSection.button3')}
                    </Button>
                </div>
            </div>
        </section>
        {showMorgiPackagesModal &&
            <MorgisPackagesModal
                onClose={() => { setShowMorgiPackagesModal(false) }} />
        }</>

    );
};

export default FeaturesSection;
